





















































































































































import { getExamPapers, setExamPaper, uploadExamPapers } from "@/api/examPaper";
import {
  getTestPaperList,
  getTestPaperGroupCascadeList,
} from "@/api/testPaper";
import { uuid } from "@/tool";
import { ExamPaper, ExamProjectPro } from "@/tool/_class";
import { formatTime, getDiffDate } from "@/utils/date";
import { Component, Vue, Prop } from "vue-property-decorator";
import * as _ from "lodash";
import dayjs from "dayjs";
import { SrvRecord } from "dns";

interface TestPaperFile {
  fileDate: Date;
  fileName: string;
  fileUrl: string;
  name: string;
  withinDays: number;
}

interface UseTime {
  date: string;
  start: string;
  end: string;
  title: string;
  type: string;
}

@Component({
  name: "ImportExamPaper",
})
export default class extends Vue {
  @Prop() private examProjectProfile!: ExamProjectPro;
  @Prop() private currentExamPaper!: ExamPaper;
  private choosePaperType: string = "testPaper";
  private fileList: Array<{ name: string; url: string }> = [];
  private differDate: UseTime[] = [];
  private examData: any = {};
  private selectObj: {
    fileName?: string;
    fileUrl?: string;
    hashCode?: string;
    name?: string;
    testPaperUnzipCode?: string;
    testPaperFileHashCode?: string;
    testPaperNo?: string;
  } = {};
  private examPaperList: Array<TestPaperFile> = [];
  private examPaperListCopy: Array<TestPaperFile> = [];
  private dateEnd1: any = {};
  private dateBegin1: any = {};

  private useTimeType: string = "custom";
  private startTime: string = "";
  private endTime: string = "";

  private disabled(): boolean {
    if (
      (this.examData.type != "permanent" &&
        this.examData.type != "custom" &&
        !this.examData.date) ||
      (!this.selectObj.fileUrl && !this.currentExamPaper.examPaperFileUrl)
    ) {
      return true;
    }
    return false;
  }
  private disabled2(): boolean {
    if (
      this.examData.type === "custom" &&
      (!this.examData.start || !this.examData.end)
    ) {
      return true;
    }
    return false;
  }

  private testPaperGroupCascadeOptions = [];

  private props = {
    lazy: true,
    emitPath: false,
    label: "displayText",
    value: "data",
    lazyLoad: async (node: any, resolve: any) => {
      const nodeData = node.data;
      if (nodeData.dataType == "testpaper") {
        resolve([]);
        return;
      }
      _.set(nodeData, "leaf", false);
      if (nodeData.dataType == "grade") {
        //年级不查找试卷
        resolve([]);
        return;
      }

      const params = {
        curPage: 1,
        pageSize: 500,
        term: nodeData.data.termName,
        term02: nodeData.data.termName02,
        gradeNo: nodeData.data.gradeNo,
        grade: nodeData.data.gradeName,
        keyWords: "",
      };
      console.log(`params: ${JSON.stringify(params)}`);
      //
      const res = await getTestPaperList(params);
      let items: any[] = res.data.items.map((item: any) => {
        let result = {
          keyId: item.testPaperId,
          keyValue: item.testPaperId,
          displayText: item.testPaperName,
          parentKeyId: nodeData.keyId,
          dataType: "testpaper",
          leaf: true,
          data: {
            fileName: item.testPaperFileName,
            fileUrl: item.testPaperFileUrl,
            hashCode: item.testPaperFileHashCode,
            name: item.testPaperName,
            testPaperFileHashCode: item.testPaperFileHashCode,
            testPaperNo: item.testPaperNo,
          },
        };
        return result;
      });

      console.log(`${JSON.stringify(node.data)}`);
      resolve(items);
    },
  };

  handleCascadeTestPaperChange(data: any) {
    console.log(`handleCascadeTestPaperChange: ${JSON.stringify(data)}`);
  }

  private beginDataChange1(times: { getTime: () => number }) {
    if (times) {
      this.dateEnd1 = {
        disabledDate(time: { getTime: () => number }) {
          return time.getTime() < times.getTime();
        },
      };
    }
  }
  private endDataChange1(times: { getTime: () => number }) {
    if (times) {
      this.dateBegin1 = {
        disabledDate(time: { getTime: () => number }) {
          return time.getTime() > times.getTime();
        },
      };
    }
  }

  handleUploadSuccess(response: any, file: any, fileList: any) {
    this.fileList.splice(0, this.fileList.length);
    //alert("success");
    if (response!.code === 0) {
      this.$message({
        showClose: true,
        message: "上传成功",
        type: "success",
      });
      //
      this.getExamPapersData();
      //
    } else {
      this.$message.error(`上传失败:${response!.msg || ""}`);
    }
  }

  handleUploadError(err: any, file: any, fileList: any) {
    //alert("error");
    this.fileList.splice(0, this.fileList.length);
    this.$message.error(`上传失败,${err!.message || ""}`);
  }

  handleRemove(
    file: { name: string; url: string },
    fileList: Array<{ name: string; url: string }>
  ) {
    console.log(file, fileList);
  }
  handlePreview(file: string) {
    console.log(file);
  }
  handleExceed(
    files: Array<{ name: string; url: string }>,
    fileList: Array<{ name: string; url: string }>
  ) {
    this.$message.warning(
      `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
        files.length + fileList.length
      } 个文件`
    );
  }
  beforeRemove(
    file: { name: string; url: string },
    fileList: Array<{ name: string; url: string }>
  ) {
    return this.$confirm(`确定移除 ${file.name}？`);
  }

  handleUploadRequest(params: any) {
    const file = params.file;
    let formData = new FormData();
    formData.append("file01", file, "file01.xsjz"); //'file01.xsjz'/*this.file
    //formData.append(file.name, this.file, file.name);
    uploadExamPapers(formData, {
      file01: file.name,
    })
      .then((res: any) => {
        this.fileList.splice(0, this.fileList.length);
        if (res.code == 0) {
          this.getExamPapersData();
          this.$message({
            type: "success",
            message: "上传成功!",
          });
        }
      })
      .catch((error) => {
        this.fileList.splice(0, this.fileList.length);
        this.$message.error(`上传失败,${error.msg || error!.message || ""}`);
      });
  }

  //上传文件，获取文件流
  handleFileChange(file: any) {
    return;
    /*
    this.file = file.raw;
    let formData = new FormData();
    formData.append('file01', this.file, 'file01.xsjz');//'file01.xsjz'/*this.file
    //formData.append(file.name, this.file, file.name);
    uploadExamPapers(formData, {
      file01: file.name
    }).then((res: any) => {
      if (res.code == 0) {
        this.getExamPapersData();
        this.$message({
          type: "success",
          message: "上传成功!"
        });
      }
    });
    */
  }

  private formatDate(date: Date): string {
    return dayjs(date).format("YYYY-MM-DD");
  }

  private filterMethod(query: string) {
    if (!query.trim()) {
      //查询条件为空
      this.examPaperList = this.examPaperListCopy;
      return;
    }

    console.log(`filterMethod, query: ${query}`);

    //
    let keyWords: string[] = query.split(" ");
    if (!keyWords.length) {
      keyWords = query.split(",");
    }
    if (!keyWords.length) {
      keyWords = query.split(";");
    }
    //
    this.examPaperList = this.examPaperListCopy.filter((item) => {
      let ss: string = `${item.name}-${this.formatDate(item.fileDate)}`;
      let result: boolean = true;
      for (let i = 0; i < keyWords.length; i++) {
        result = result && ss.indexOf(keyWords[i]) > -1;
      }
      return result;
    });
  }

  private handleStartTimeChange(value: any) {
    this.examData.start = this.startTime;
  }

  private handleEndTimeChange(value: any) {
    this.examData.end = this.endTime;
  }

  private handleUseTimeRadio(value: string) {
    //鼠标点选才触发
    const useTime = this.differDate.find((item) => {
      return item.type == value;
    });
    if (useTime) {
      _.merge(this.examData, useTime);
      _.keys(useTime).forEach((key) => {
        this.$set(this.examData, key, _.get(useTime, key));
      });
      this.startTime = this.examData.start;
      this.endTime = this.examData.end;
    }
    //
  }

  private time() {
    let examStartTime = this.examProjectProfile.examStartTime.substring(0, 10);
    let examEndTime = this.examProjectProfile.examEndTime.substring(0, 10);
    getDiffDate(examStartTime, examEndTime).forEach((el) => {
      let time = el.substring(0, 10);
      let obj: UseTime = {
        date: time,
        start: `${time} 07:00:00`,
        end: `${time} 12:29:00`,
        title: time + "上午",
        type: `am:${time}`,
      };
      let obj2: UseTime = {
        date: time,
        start: `${time} 12:30:00`,
        end: `${time} 21:30:00`,
        title: time + "下午",
        type: `pm:${time}`,
      };
      let obj3: UseTime = {
        date: time,
        start: `${time} 07:00:00`,
        end: `${time} 21:30:00`,
        title: time + "全天",
        type: `day:${time}`,
      };
      this.differDate.push(obj, obj2, obj3);
    });
    let obj4: UseTime = {
      date: "",
      start: "",
      end: "",
      title: "长期有效",
      type: "permanent",
    };
    let obj5: UseTime = {
      date: "",
      start: "",
      end: "",
      title: "自定义",
      type: "custom",
    };
    this.differDate.push(obj4, obj5);
    return;
  }

  private doTestClick() {
    alert(this.currentExamPaper.examPaperId);
    return;
  }

  private setExamPaperData() {
    if (this.examData.type != "permanent") {
      //非长期有效的，格式化时间数据
      const fmt = "yyyy-MM-dd HH:mm:ss";
      this.examData.start = formatTime(this.examData.start, fmt);
      this.examData.end = formatTime(this.examData.end, fmt);
    }
    if (
      !(this.selectObj.fileName && this.selectObj.fileUrl) &&
      !(
        this.currentExamPaper.examPaperFileName &&
        this.currentExamPaper.examPaperFileUrl
      )
    ) {
      this.$message({
        type: "warning",
        message: `选择的试卷信息不全。`,
      });
      return;
    }
    console.log(
      `setExamPaperData selectObj: ${JSON.stringify(this.selectObj)}`
    );
    let examPapers = _.merge({}, this.currentExamPaper, {
      examProjectId: this.examProjectProfile.examProjectId,
      examPaperNo:
        this.selectObj.testPaperNo || this.currentExamPaper.examPaperNo || "",
      examPaperName: this.selectObj.name || this.currentExamPaper.examPaperName,
      examPaperUnzipCode: this.currentExamPaper.examPaperUnzipCode || "",
      examPaperFileName:
        this.selectObj.fileName || this.currentExamPaper.examPaperFileName,
      fileHashCode:
        this.selectObj.hashCode ||
        this.selectObj.testPaperFileHashCode ||
        this.currentExamPaper.fileHashCode,
      sourceType: {
        paperFileName: "",
        validateCodeFileName: "",
      },
      examStartTime:
        this.examData.type === "permanent"
          ? "1971-01-01 00:00:00"
          : this.examData.start,
      /*
        this.examData.type === "custom"
          ? this.examData.start
          : this.examData.type === "permanent"
          ? "1971-01-01 00:00:00"
          : `${this.examData.date} ${this.examData.start}`
          */
      examEndTime:
        this.examData.type === "permanent"
          ? "9999-12-30 00:00:00"
          : this.examData.end,
      /*
        this.examData.type === "custom"
          ? this.examData.end
          : this.examData.type === "permanent"
          ? "9999-12-30 00:00:00"
          : `${this.examData.date} ${this.examData.end}`
          */
      examPaperFileUrl:
        this.selectObj.fileUrl || this.currentExamPaper.examPaperFileUrl,
      examStartTimeStr: "",
      examEndTimeStr: "",
    });
    if (!examPapers.examPaperId) {
      examPapers.examPaperId = uuid();
    }
    //
    setExamPaper(examPapers).then((res: any) => {
      if (res.code == 0) {
        const item = this.examProjectProfile.examPapers.find((item) => {
          return item.examPaperId == examPapers.examPaperId;
        });
        if (item) {
          _.merge(item, examPapers);
        } else {
          this.examProjectProfile.examPapers.push(
            _.merge(new ExamPaper(), examPapers)
          );
        }
        //
        this.$message({
          type: "success",
          message: "添加试卷成功",
        });
        this.$emit("closeShowDialog");
      } else {
        this.$message({
          type: "warning",
          message: res.msg,
        });
      }
    });
  }

  async getTestPaperListData(params: {
    curPage: number;
    pageSize: number;
    testPaperName?: string;
    term: string;
    term02: string;
    gradeNo: string;
    grade: string;
    schoolId?: string;
    keyWords: string;
  }) {
    const res = await getTestPaperList(params);
  }

  async getTestPaperGroupCascadeListData() {
    const res = await getTestPaperGroupCascadeList({});
    this.testPaperGroupCascadeOptions = res.data.items;
  }

  private loadingExamPaper: boolean = false;
  async getExamPapersData() {
    try {
      this.loadingExamPaper = true;
      //
      const res = await getExamPapers({});
      console.log(res);
      this.examPaperList = res.data.items.sort((a: any, b: any) => {
        if (a.withinDays < b.withinDays) {
          return -1;
        } else if (a.withinDays > b.withinDays) {
          return 1;
        } else {
          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          } else {
            return 0;
          }
        }
      });
      this.examPaperList.forEach((item) => {
        item.fileDate = dayjs(item.fileDate).toDate();
      });
      this.examPaperListCopy.splice(0, this.examPaperListCopy.length);
      this.examPaperListCopy.push(...this.examPaperList);
    } finally {
      this.loadingExamPaper = false;
    }
  }

  private async initData() {
    this.time();
    this.examData.start = dayjs(this.currentExamPaper.examStartTime).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    this.examData.end = dayjs(this.currentExamPaper.examEndTime).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    const useTime = this.differDate.find((item) => {
      return item.start == this.examData.start && item.end == this.examData.end;
    });
    this.useTimeType = useTime ? useTime.type : "custom";
    this.examData.type = this.useTimeType;
    this.examData.date = useTime?.date || "";

    this.startTime = this.examData.start;
    this.endTime = this.examData.end;

    // this.getExamPapersData();
    await this.getTestPaperGroupCascadeListData();
    console.log(`currentExamPaper: ${JSON.stringify(this.currentExamPaper)}`);
  }

  mounted() {
    this.initData();
  }
  beforeDestroy() {
    // alert("test");
  }
}
