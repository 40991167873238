import request from '@/utils/request'

export const setExamPaper = (data: any) =>
  request({
    // 添加/更新试卷
    url: '/v1/examPaper',
    method: 'POST',
    data
  })

export const getExamPaperList = (params: { examProjectId?: string, examPaperId?: string, pageSize: number, curPage: number }) =>
  request({
    // 获取试卷列表
    url: '/v1/ExamPaperList',
    method: 'GET',
    params
  })

export const queryExamPaper = (examPaperId: string) =>
  request({
    //  获取试卷by examPaperId
    url: `/v1/examPaper/${examPaperId}`,
    method: 'GET'
  })

export const deleteExamPaper = (examPaperId: string) =>
  request({
    // 删除试卷by examPaperId
    url: `/v1/examPaper/${examPaperId}`,
    method: 'DELETE'
  })

export const uploadExamPapers = (data: any, params: any) =>
  request({
    // 上传试卷
    url: '/v1/upload/examPapers',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Accept-Language': 'zh-CN,zh;q=0.9',
    },
    data,
    params
  })
export const getExamPapers = (params: { lastDays?: string, fileName?: string }) =>
  request({
    //  试卷文件列表
    url: '/v1/resource/examPapers',
    method: 'GET',
    params
  })

