var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('div',[_c('div',[_vm._m(0),_c('el-upload',{attrs:{"action":"","limit":1,"accept":".xlsx, .xls","before-upload":_vm.beforeUpload,"on-change":_vm.handleChangeFile,"show-file-list":false}},[_c('el-button',[_vm._v("导入报名表")])],1)],1),_c('div',{staticStyle:{"margin-top":"10px"}},[(_vm.fileName)?_c('el-tag',{attrs:{"size":"small","type":"info"}},[_vm._v(_vm._s(_vm.fileName))]):_vm._e()],1),_c('div',[_c('p',{attrs:{"size":"small","type":"primary"}},[_vm._v("选择考点")]),_c('el-cascader',{staticStyle:{"width":"100%"},attrs:{"options":_vm.examSchools,"props":{
          value: 'keyId',
          label: 'keyValue',
          leaf: 'schoolId',
          expandTrigger: 'hover',
        },"filterable":"","filter-method":_vm.filterMethod,"show-all-levels":false},on:{"change":_vm.handleExamSchoolChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var data = ref.data;
return [_c('span',[_vm._v(_vm._s(_vm.cascaderItemLabel(data)))]),(!(data.dataType == 'school'))?_c('span',[_vm._v(" ("+_vm._s(data.children.length)+") ")]):_vm._e()]}}]),model:{value:(_vm.cascadeExamSchoolId),callback:function ($$v) {_vm.cascadeExamSchoolId=$$v},expression:"cascadeExamSchoolId"}})],1),_c('div',[_vm._m(1),_c('el-select',{attrs:{"multiple":"","placeholder":"请选择考场"},model:{value:(_vm.xlxsList.examRoomIds),callback:function ($$v) {_vm.$set(_vm.xlxsList, "examRoomIds", $$v)},expression:"xlxsList.examRoomIds"}},_vm._l((_vm.examRooms),function(item){return _c('el-option',{key:item.examRoomId,attrs:{"label":item.examRoomName,"value":item.examRoomId}},[_c('span',{staticStyle:{"float":"left"}},[_vm._v(_vm._s(item.examRoomName))]),_c('span',{staticStyle:{"float":"right","color":"#8492a6","font-size":"13px"}},[_vm._v(_vm._s(item.schoolName))])])}),1)],1),_c('div',[_c('p',[_vm._v("必选字段")]),_c('el-checkbox-group',{model:{value:(_vm.checkedFields),callback:function ($$v) {_vm.checkedFields=$$v},expression:"checkedFields"}},_vm._l((_vm.examStudentFields),function(item){return _c('el-checkbox',{key:item.value,attrs:{"label":item.value,"disabled":item.disabled}},[_vm._v(_vm._s(item.label))])}),1)],1)]),_c('div',{staticStyle:{"text-align":"right","margin":"30px 0"}},[_c('el-button',{attrs:{"type":"primary","loading":_vm.loading,"disabled":_vm.disabled},on:{"click":_vm.sub}},[_vm._v("添加")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" 请导入报名表 "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("（ 建议：文件名尽量以学校命名，文件名需要附带其他信息请用下划线 [ _ ] 分隔，如：某某中学_000.xlsx ）")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{attrs:{"size":"small","type":"primary"}},[_vm._v(" 选择考场"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("（ 如果考点同时存在多个考试项目安排，则需通过考场划分项目在指定的考场 ）")])])}]

export { render, staticRenderFns }